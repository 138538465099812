import { type RenewalType } from '../models/RenewalType'

export const renewalTypes: RenewalType[] = [
  {
    id: 1,
    name: 'Miudiño',
    price: 0
  },
  {
    id: 2,
    name: 'Neno (10 a 16 anos)',
    price: 15
  },
  {
    id: 3,
    name: 'Adulto',
    price: 20
  },
  {
    id: 4,
    name: 'Alta + Bufanda',
    price: 30
  }
]

export const getRenewalTypeById = (id: number): RenewalType => {
  return renewalTypes.find(renewalType => renewalType.id.toLocaleString() === id.toLocaleString()) as RenewalType
}
