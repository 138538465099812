import { mailerConfig } from '../config/mailer'
import { formRsvpDataToSendEmailDataMapper } from '../mappers/formDataToSendEmailDataMapper'
import { type FormData } from '../models/FormData'
import { MailTemplates } from '../models/MailTemplates'
import { type AnniversaryFormData } from '../models/AnniversaryFormData'
import { anniversaryFormRsvpDataToSendEmailDataMapper } from '../mappers/anniversaryFormDataToSendEmailDataMapper'
import { type HookieFormData } from '../models/HookieFormData'
import { hookieFormRsvpDataToSendEmailDataMapper } from '../mappers/hookieFormDataToSendEmailDataMapper'
import { type ChristmasDinnerFormData } from '../models/ChristmasDinnerFormData'
import {
  christmasDinnerFormRsvpDataToSendEmailDataMapper
} from '../mappers/christmasDinnerFormDataToSendEmailDataMapper'

const url = (mailerConfig.secure ? 'https' : 'http') + '://' + mailerConfig.host + '/' + mailerConfig.folder
const headers = {
  'Content-Type': 'application/json'
}

const getToken = async (): Promise<string> => {
  try {
    const response = await fetch(`${url}/login`, {
      method: 'POST',
      headers,
      body: JSON.stringify(mailerConfig.auth)
    })

    const data = await response.json()
    return data.token
  } catch (error: any) {
    return ''
  }
}

const sendMail = async (data: any, token: string): Promise<boolean> => {
  const response = await fetch(`${url}/send-email`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })

  const result = await response.json()
  return result.success
}

export const sendEmailRegistration = async (data: FormData): Promise<void> => {
  const token = await getToken()

  const dataEmail = formRsvpDataToSendEmailDataMapper(data)
  dataEmail.template = MailTemplates.REGISTER_COMPLETED
  await sendMail(dataEmail, token)

  dataEmail.template = MailTemplates.REGISTER_ADMIN_NOTIFICATION
  dataEmail.to = mailerConfig.adminEmail
  await sendMail(dataEmail, token)
}

export const sendEmailHookie = async (data: HookieFormData): Promise<void> => {
  const token = await getToken()

  const dataEmail = hookieFormRsvpDataToSendEmailDataMapper(data)
  dataEmail.template = MailTemplates.HOOKIE_REGISTER
  await sendMail(dataEmail, token)

  dataEmail.template = MailTemplates.HOOKIE_REGISTER_ADMIN_NOTIFICATION
  dataEmail.to = mailerConfig.adminEmail
  await sendMail(dataEmail, token)
}

export const sendEmailAnniversarySignUp = async (data: AnniversaryFormData): Promise<void> => {
  const token = await getToken()

  const dataEmail = anniversaryFormRsvpDataToSendEmailDataMapper(data)
  dataEmail.template = MailTemplates.ANNIVERSARY_SIGNUP_COMPLETED
  await sendMail(dataEmail, token)

  dataEmail.template = MailTemplates.ANNIVERSARY_SIGNUP_ADMIN_NOTIFICATION
  dataEmail.to = mailerConfig.adminEmail
  await sendMail(dataEmail, token)
}

export const sendEmailChristmasDinnerSignUp = async (data: ChristmasDinnerFormData): Promise<void> => {
  const token = await getToken()

  const dataEmail = christmasDinnerFormRsvpDataToSendEmailDataMapper(data)
  dataEmail.template = MailTemplates.CHRISTMAS_DINNER_SIGNUP_COMPLETED
  await sendMail(dataEmail, token)

  dataEmail.template = MailTemplates.CHRISTMAS_DINNER_SIGNUP_ADMIN_NOTIFICATION
  dataEmail.to = mailerConfig.adminEmail
  await sendMail(dataEmail, token)
}
