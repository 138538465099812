const config = {
  REGISTER_AVAILABLE: true,
  ANNIVERSARY_AVAILABLE: false,
  HOOKIE_AVAILABLE: false,
  CHRISTMAS_DINNER_AVAILABLE: false,

  LIMIT_CHRISTMAS_DINNER: 62,

  URL_FIREBASE_STORAGE: 'https://firebasestorage.googleapis.com/v0/b/lechuzas-celestes.appspot.com/o/',

  SEASON: '2024-2025',

  // BIZUM_PHONE: '636639707' // Old phone
  // BIZUM_PHONE: '655020098' // David's phone
  BIZUM_PHONE: '657080328' // New phone
}

export default config
