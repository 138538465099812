import { type SizeType } from '../models/SizeType'

export const sizeTypes: SizeType[] = [
  { name: 'XS', available: true },
  { name: 'S', available: true },
  { name: 'M', available: true },
  { name: 'L', available: true },
  { name: 'XL', available: true },
  { name: 'XXL', available: true }
]
