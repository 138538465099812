import { type ChristmasDinnerFormData } from '../models/ChristmasDinnerFormData'
import { type SendEmailChristmasDinnerData } from '../models/SendEmailChristmasDinnerData'

export const christmasDinnerFormRsvpDataToSendEmailDataMapper = (data: ChristmasDinnerFormData): SendEmailChristmasDinnerData => {
  return {
    to: data.email,
    template: '',
    vars: {
      name: data.name,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      bus: data.bus
    }
  }
}
