import React, { useState } from 'react'
import {
  Box, Button,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Heading, Input, Select, Spinner, Stack, Text,
  useColorModeValue
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import { type FirebaseResponse, sendHookie } from '../../services/firebaseService'
import config from '../../config/config'
import { sizeTypes } from '../../lib/sizeTypes'
import { type HookieFormData } from '../../models/HookieFormData'

const HookieForm: React.FC = () => {
  const [isSending, setIsSending] = useState(false)

  const availableSizes = sizeTypes.filter(sizeType => sizeType.available)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<HookieFormData>()

  const submitForm = handleSubmit((data: HookieFormData) => {
    setIsSending(true)
    void sendHookie(data).then(async r => {
      await showAlert(r)
      setIsSending(false)

      reset()
    })
  })

  const showAlert = async (r: FirebaseResponse<string>): Promise<void> => {
    const title = r.success ? 'Grazas!' : 'Ups!'
    const text = r.success
      ? 'Recibimos a túa solicitude para adquirir o teu novo suadoiro'
      : 'Algo foi mal, por favor, tenta de novo mais tarde.'
    const icon = r.success ? 'success' : 'error'
    const confirmButtonText = 'Pechar'
    await Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

  return (
    <>
      <Flex
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} mt={'70px'} w={'100%'}>
          <Stack align={'center'}>
            <Heading
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}>
              Suadoiro
              <Text as={'span'} color={'cyan.400'} display={'block'}>
                {config.SEASON}
              </Text>
            </Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                void (async () => {
                  await submitForm()
                })()
              }}
            >
              <Stack spacing={4}>
                <FormControl isInvalid={errors.name != null}>
                  <FormLabel>Nome</FormLabel>
                  <Input
                    type="text"
                    placeholder="Nome"
                    {...register('name', {
                      required: 'Tes que indicar un nome',
                      minLength: { value: 2, message: 'O nome debe ter polo menos 2 caracteres' }
                    })}
                  />
                  <FormErrorMessage>
                    {errors.name?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.lastName != null}>
                  <FormLabel>Apelidos</FormLabel>
                  <Input
                    type="text"
                    placeholder="Apelidos"
                    {...register('lastName', {
                      required: 'Tes que indicar os apelidos',
                      minLength: { value: 4, message: 'Os apelidos deben ter polo menos 4 caracteres' }
                    })}
                  />
                  <FormErrorMessage>
                    {errors.lastName?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.phone != null}>
                  <FormLabel>Teléfono</FormLabel>
                  <Input
                    type="text"
                    placeholder="Teléfono"
                    {...register('phone', {
                      required: 'Tes que indicar un teléfono'
                    })}
                  />
                  <FormErrorMessage>
                    {errors.phone?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.email != null}>
                  <FormLabel>E-mail</FormLabel>
                  <Input
                    type="text"
                    placeholder="E-mail"
                    {...register('email', {
                      required: 'Tes que indicar un e-mail',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Tes que indicar un e-mail válido'
                      }
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.size != null}>
                  <FormLabel>Talla</FormLabel>
                  <Select
                    placeholder="Talla"
                    {
                      ...register('size', {
                        required: 'Tes que indicar unha talla'
                      })
                    }
                  >
                    {
                      availableSizes.map(
                        (sizeType, index) => <option key={index} value={sizeType.name}>{sizeType.name}</option>)
                    }
                  </Select>
                  <FormErrorMessage>
                    {errors.size?.message}
                  </FormErrorMessage>
                </FormControl>
                <hr/>
                <Stack spacing={10}>
                  {
                    isSending
                      ? <Text><Spinner/> Enviando datos...</Text>
                      : <Button
                        type="submit"
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                          bg: 'blue.500'
                        }}>
                        Enviar datos
                      </Button>
                  }
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </>
  )
}

export default HookieForm
