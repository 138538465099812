import React, { useEffect, useState } from 'react'
import {
  Box, Button,
  Flex, FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input, Radio, RadioGroup, Spinner,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import {
  type FirebaseResponse, getNumberOfSignUpsChristmasDinner,
  sendChristmasDinnerSignUp
} from '../../services/firebaseService'
import Swal from 'sweetalert2'
import { type ChristmasDinnerFormData } from '../../models/ChristmasDinnerFormData'
import config from '../../config/config'

const ChristmasDinnerForm: React.FC = () => {
  const [isSending, setIsSending] = useState(false)
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false)
  const [isFull, setIsFull] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ChristmasDinnerFormData>()

  const submitForm = handleSubmit((data: ChristmasDinnerFormData) => {
    setIsSending(true)

    void sendChristmasDinnerSignUp(data).then(async r => {
      await showAlert(r)
      setIsSending(false)
      reset()
    })
  })

  const showAlert = async (r: FirebaseResponse<string>): Promise<void> => {
    const title = r.success ? 'Grazas!' : 'Ups!'
    const text = r.success
      ? 'A túa confirmación de asistencia foi enviada correctamente!'
      : 'Algo foi mal, por favor, tenta de novo mais tarde.'
    const icon = r.success ? 'success' : 'error'
    const confirmButtonText = 'Pechar'
    await Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    })
  }

  useEffect(() => {
    setIsCheckingAvailability(true)
    void getNumberOfSignUpsChristmasDinner().then(async r => {
      console.log('disponibilidade', r)
      setIsCheckingAvailability(false)
      setIsFull(r >= config.LIMIT_CHRISTMAS_DINNER)
    })
  }, [])

  return (
        <Flex
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} mt={'70px'}>
                <Stack align={'center'}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                        lineHeight={'110%'}>
                        Cea de Nadal&nbsp;<br/>
                        <Text as={'span'} color={'cyan.400'}>
                            Lechuzas Celestes
                        </Text>
                    </Heading>
                    <Heading fontSize={'l'}>
                        Cubre os seguintes datos para inscribirte á cea de Nadal. <br/>
                        14 de decembro ás 22:00h no Pino Manso.<br/>
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1023.4048550130171!2d-8.636015644036156!3d42.22269631381528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2587f9453b963f%3A0x765f9dc967e6e1a2!2sO%20Noso%20Rinc%C3%B3n%20Do%20Pino%20Manso!5e1!3m2!1sgl!2ses!4v1730734253437!5m2!1sgl!2ses"
                                loading="lazy"
                                style={{ width: '100%', height: '250px' }}
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/lechuzas-celestes.appspot.com/o/cea-lechuzas-nadal-2024.jpg?alt=media&token=0ae47941-d974-4151-bb75-b321bc814cac"
                            alt="Cea de Nadal" style={{ width: '100%' }}/>
                    </Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    {
                        isFull
                          ? <Text>Sentímolo, pero xa se cubriron todas as prazas para a cea de Nadal.</Text>
                          : isCheckingAvailability
                            ? <Text><Spinner/> Comprobando dispoñibilidade...</Text>
                            : <form
                        onSubmit={(event) => {
                          event.preventDefault()
                          void (async () => {
                            await submitForm()
                          })()
                        }}
                    >
                        <Stack spacing={4}>
                            <FormControl isInvalid={errors.name != null}>
                                <FormLabel>Nome</FormLabel>
                                <Input
                                    type="text"
                                    placeholder="Nome"
                                    {...register('name', {
                                      required: 'Tes que indicar un nome',
                                      minLength: {
                                        value: 2,
                                        message: 'O nome debe ter polo menos 2 caracteres'
                                      }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.name?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.lastName != null}>
                                <FormLabel>Apelidos</FormLabel>
                                <Input
                                    type="text"
                                    placeholder="Apelidos"
                                    {...register('lastName', {
                                      required: 'Tes que indicar os apelidos',
                                      minLength: {
                                        value: 2,
                                        message: 'Os apelidos deben ter polo menos 2 caracteres'
                                      }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.lastName?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.phone != null}>
                                <FormLabel>Teléfono</FormLabel>
                                <Input
                                    type="text"
                                    placeholder="Teléfono"
                                    {...register('phone', {
                                      required: 'Tes que indicar un teléfono',
                                      pattern: {
                                        value: /^\d{9}$/,
                                        message: 'Tes que indicar un teléfono válido'
                                      }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.phone?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.email != null}>
                                <FormLabel>E-mail</FormLabel>
                                <Input
                                    type="text"
                                    placeholder="E-mail"
                                    {...register('email', {
                                      required: 'Tes que indicar un e-mail',
                                      pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Tes que indicar un e-mail válido'
                                      }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.email?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.email != null}>
                                <FormLabel>¿Precisas bus?</FormLabel>
                                <RadioGroup>
                                    <Stack direction="row">
                                        <Radio
                                            value="Sí" {...register('bus', { required: 'Tes que indicar se precisas bus' })}>Si</Radio>
                                        <Radio
                                            value="Non" {...register('bus', { required: 'Tes que indicar se precisas bus' })}>Non</Radio>
                                    </Stack>
                                </RadioGroup>
                                <Text fontSize="sm" color="gray.500" align="left">
                                    O bus terá un custe adicional de 10€, e a súa dispoñibilidade está condicionada a un
                                    mínimo de 30 personas. <br/>
                                    Sairá ás 21:00h dende a cervexería The Movie, en Balaidos, e ás 21.15h dende o
                                    Concheiro.
                                </Text>
                                <FormErrorMessage>
                                    {errors.bus?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <hr/>
                            <Stack spacing={10}>
                                {
                                    isSending
                                      ? <Text><Spinner/> Enviando datos...</Text>
                                      : <Button
                                            type="submit"
                                            bg={'blue.400'}
                                            color={'white'}
                                            _hover={{
                                              bg: 'blue.500'
                                            }}>
                                            Inscribirse
                                        </Button>
                                }
                            </Stack>
                        </Stack>
                    </form>

                    }
                </Box>
            </Stack>
        </Flex>
  )
}

export default ChristmasDinnerForm
