import { type SendEmailData } from '../models/SendEmailData'
import { type FormData } from '../models/FormData'
import { getRenewalTypeById } from '../lib/renewalTypes'
import config from '../config/config'

const birthDateToAge = (birthDate: string): number => {
  const today = new Date()
  const birthDateObj = new Date(birthDate)
  let age = today.getFullYear() - birthDateObj.getFullYear()
  const month = today.getMonth() - birthDateObj.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDateObj.getDate())) {
    age--
  }
  return age
}

export const formRsvpDataToSendEmailDataMapper = (data: FormData): SendEmailData => {
  return {
    to: data.email,
    template: '',
    vars: {
      name: data.name,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      dni: data.dni,
      birthDate: data.birthDate,
      child: birthDateToAge(data.birthDate) < 18,
      tutorName: data.tutorName,
      tutorDni: data.tutorDni,
      address: data.address,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      partnerNumber: data.partnerNumber,
      partnerId: data.partnerId,
      harrow: data.harrow,
      renewalType: getRenewalTypeById(parseInt(data.renewalType ?? 0)).name,
      fee: getRenewalTypeById(parseInt(data.renewalType ?? 0)).price,
      phoneBizum: config.BIZUM_PHONE
    }
  }
}
